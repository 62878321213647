import { HomePages } from "../../../utility/enums";
import {
  ALERT,
  CHANGE_PASSWORD_MODAL,
  CONFIG_MODAL,
  CREATE_USER_MODAL,
  SHOW_SIDEBAR,
  STEP_NUMBER,
} from "./actionTypes";

const INIT_STATE = {
  stepNumber: HomePages.GATEWAYS,
  showConfigModal: false,
  showCreateUserModal: false,
  showChangePasswordModal: false,
  showSidebar: false,
  alert: null,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STEP_NUMBER:
      return {
        ...state,
        stepNumber: action.payload,
      };
    case CONFIG_MODAL:
      return {
        ...state,
        showConfigModal: action.payload,
      };
    case CREATE_USER_MODAL:
      return {
        ...state,
        showCreateUserModal: action.payload,
      };
    case CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        showChangePasswordModal: action.payload,
      };
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      };
    case ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return state;
  }
};

export default Auth;
