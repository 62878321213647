import { combineReducers } from "redux";
import Auth from "./auth/reducer";
import Ui from "./ui/reducer";
import Configs from "./configs/reducers";
import Version from "./version/reducers";
import Deploy from "./deploy/reducers";
import Gateway from "./gateway/reducers";
import ClientInfo from "./clientInfo/reducers";
import DeployHistory from "./deployHistory/reducers";

const rootReducer = combineReducers({
  auth: Auth,
  ui: Ui,
  configs: Configs,
  version: Version,
  deploy: Deploy,
  gateway: Gateway,
  clientInfo: ClientInfo,
  deployHistory: DeployHistory,
});

export default rootReducer;
