import {
  ADD_CLIENT_INFO,
  SET_SELECTED_CLIENT_INFO,
  GET_CLIENT_INFO,
  DELETE_CLIENT_INFO,
} from "./actionTypes";

const INIT_STATE = {
  selectedClientInfo: {},
  clientInfos: [],
};

const ClientInfos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_CLIENT_INFO:
      return {
        ...state,
        selectedClientInfo: action.payload,
      };
    case ADD_CLIENT_INFO:
      return {
        ...state,
        clientInfos: [...state.clientInfos, action.payload],
      };
    case DELETE_CLIENT_INFO:
      return {
        ...state,
        clientInfos: (state.clientInfos || []).filter(
          (clientInfo) => clientInfo._id !== action.payload
        ),
      };
    case GET_CLIENT_INFO:
      return {
        ...state,
        clientInfos: action.payload,
      };

    default:
      return state;
  }
};

export default ClientInfos;
