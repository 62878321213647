import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  LOGIN,
  LOGOUT,
  REMOVE_AUTH_ALERT,
  GET_USERS,
  UPDATE_USER,
  REMOVE_USER,
  USERS_LOADING,
  ADD_USER,
} from "./actionTypes";

const INIT_STATE = {
  isLogin: false,
  authError: "",
  authSuccess: "",
  user: {},
  users: [],
  usersLoading: true,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_USER:
      const tmp = state.users;
      return {
        ...state,
        users: [...tmp, action.payload],
      };
    case LOGIN:
      return {
        ...state,
        isLogin: true,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLogin: false,
        authError: "",
        authSuccess: "",
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: action.payload,
      };
    case REMOVE_USER:
      let filteredUsers = (state.users || []).filter(
        (user) => user._id !== action.payload
      );
      return {
        ...state,
        users: filteredUsers,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        authSuccess: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
      };
    case REMOVE_AUTH_ALERT:
      return {
        ...state,
        authSuccess: "",
        authError: "",
      };
    default:
      return state;
  }
};

export default Auth;
