export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const REMOVE_AUTH_ALERT = "REMOVE_AUTH_ALERT";
export const GET_USERS = "GET_USERS";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const USERS_LOADING = "USERS_LOADING";
export const ADD_USER = "ADD_USER";
